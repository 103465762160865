import { ENVIRONMENT_QUAL } from './qual';

export const environment = {
  ...ENVIRONMENT_QUAL,
  auth0: {
    audience: 'https://onemedical.com',
    clientId: 'yyOU0mGEZKrqyDY57jLQjjCpWRJAvQQ3',
    domain: 'laboratory-login.app.1life.com',
  },
  apiServer: 'https://portland.app.1life.com',
  myoneServer: 'https://portland.app.1life.com',
  sentry: {
    enabled: true,
    dsn: 'https://94fb2cb964527591835f763ad4493a4f@o4506221115408384.ingest.us.sentry.io/4506855554154496',
    environment: 'onelife-portland',
  },
  launchDarklyClientId: '605035fe824e970bccf18c09',
  stripePublishableKey:
    'pk_test_517WAN8I6Upoy4vYdDY4YyNaM2bQHskzSWEzGUnoNNCJaLC15PQWK40pTvmerXur4MYXMVMCsAoi0yQPCBsAXrLNF00DrMRauN5',
};
